<template>
  <div>
    <b-row>
      <b-col sm="12">
        <card-statistics-group
          icon="CpuIcon"
          statistic="86%"
          :statistic-title="$t('stats')"
          :statistics-items="statisticsItems"
        />
      </b-col>
    </b-row>
    <b-row class="">
      <b-col sm="12">
        <b-row>
          <b-col sm="6">
            <b-card v-if="additiviesVsCostSeries.reduce((x, y) => x + y) > 0">
              <apexchart
                height="300"
                type="pie"
                :options="additivesCostOptions"
                :series="additiviesVsCostSeries"
              />
            </b-card>
          </b-col>
          <b-col sm="6">
            <b-card v-if="incomeVsExpensesSeries.reduce((x, y) => x + y) > 0">
              <apexchart
                height="300"
                type="pie"
                :options="incomeExpensesOptions"
                :series="incomeVsExpensesSeries"
              />
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <!-- <apexchart
        width="500"
        type="pie"
        :options="options"
        :series="series"
      /> -->
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Vue from 'vue'
import CardStatisticsGroup from '@/components/cards/CardStatisticsGroup.vue'

export default {
  components: {
    CardStatisticsGroup,
  },
  data() {
    return {
      statisticsItems: [
        {
          id: 'incomes',
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '',
          subtitle: this.$t('incomes'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          id: 'expenses',
          icon: 'DollarSignIcon',
          color: 'light-danger',
          title: '',
          subtitle: this.$t('expenses'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          id: 'balance',
          icon: 'DollarSignIcon',
          color: 'light-warning',
          title: '',
          subtitle: this.$t('balance'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          id: 'additivesAndChanges',
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '',
          subtitle: this.$t('additivesAndChanges'),
          customClass: 'mb-2 mb-sm-0',
        },
        {
          id: 'direcCost',
          icon: 'BriefcaseIcon',
          color: 'light-success',
          title: '',
          subtitle: this.$t('directCost'),
          customClass: '',
        },
      ],
      self: this,
      additivesCostOptions: {
        chart: {
          id: 'vuechart-example-1',
        },
        legend: {
          position: 'bottom',
        },
        labels: [this.$t('additivesAndChanges'), this.$t('directCost')],
        colors: ['#bb81fc', '#6e32b4'],
        tooltip: {
          enabled: true,
          y: {
            formatter(value) {
              return `$${Vue.options.filters.money(value)}`
            },
            title: {
              formatter: title => title,
            },
          },
        },
      },
      incomeExpensesOptions: {
        chart: {
          id: 'vuechart-example-2',
        },
        legend: {
          position: 'bottom',
        },
        labels: [this.$t('expenses'), this.$t('incomes')],
        colors: ['#bb81fc', '#6e32b4'],
        tooltip: {
          enabled: true,
          y: {
            formatter(value) {
              return `$${Vue.options.filters.money(value)}`
            },
            title: {
              formatter: title => title,
            },
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('projects', ['project']),
    totalAdditives() {
      return `$${this.$options.filters.money(
        this.project.additives_and_changes_expenses_total,
      )}`
    },
    directCost() {
      return `$${this.$options.filters.money(
        this.project.direct_cost_expenses_total,
      )}`
    },
    additiviesVsCostSeries() {
      return [
        parseFloat(this.project.additives_and_changes_expenses_total),
        parseFloat(this.project.direct_cost_expenses_total),
      ]
    },
    totalExcomes() {
      return `$${this.$options.filters.money(this.project.expenses_total)}`
    },
    totalIncomes() {
      return `$${this.$options.filters.money(this.project.incomes_total)}`
    },
    incomeVsExpensesSeries() {
      return [
        parseFloat(this.project.expenses_total),
        parseFloat(this.project.incomes_total),
      ]
    },
  },
  created() {
    this.fetchProject(this.$route.params.projectId).then(response => {
      const [
        incomes,
        expenses,
        balance,
        addtivies,
        direcCost,
      ] = this.statisticsItems
      incomes.title = `$${this.$options.filters.money(response.incomes_total)}`
      expenses.title = `$${this.$options.filters.money(
        response.expenses_total,
      )}`
      balance.title = `$${this.$options.filters.money(
        response.incomes_total - response.expenses_total,
      )}`
      addtivies.title = `$${this.$options.filters.money(
        response.additives_and_changes_expenses_total,
      )}`
      direcCost.title = `$${this.$options.filters.money(
        response.direct_cost_expenses_total,
      )}`
    })
  },
  destroyed() {
    // this.clearProject({})
  },
  methods: {
    ...mapActions('projects', ['fetchProject']),
    ...mapMutations('projects', {
      clearProject: 'SET_PROJECT',
    }),
  },
}
</script>

<style></style>
